<template>
  <div class="help-center-container">
    <div class="help-center">
      <h1 class="title">{{ $t('helpCenterTitle') }}</h1>
    </div>
    <div class="help-topics">
      <router-link v-for="(page, index) in pages" :key="index" :to="{ name: 'help-topic', params: { slug: page.slug } }" :style="backgroundStyle(page)" class="help-topic">
        <div class="icon-container">
          <img :src="page.fields.icon" class="icon">
        </div>
        {{ page.name }}
      </router-link>
    </div>
    <h2>{{ $t('helpCenterContactoSoporte') }}</h2>
    <p>
      <span class="advisory">{{ $t('helpCenterContactoSoporteTexto') }}</span>
      <btn custom-class="button-link" customClass="contact action-link" :text="$t('helpCenterContactSupportLinkText')" @btn-click="openWidget"></btn>
    </p>
  </div>
</template>

<script>
import Btn from '@/components/basic/Btn.vue';
import { defaultLocale } from '@/constants/locales';
import { butter } from '@/buttercms';

export default {
  name: 'help-center',
  data() {
    return {
      title: 'Help Center',
      pages: [],
    };
  },
  components: {
    Btn,
  },
  created() {
    this.fetchHelpTopics();
  },
  methods: {
    fetchHelpTopics() {
      butter.page
        .list('help_page', { locale: defaultLocale.code })
        .then(res => {
          this.pages = res.data.data.sort((topicA, topicB) => {
            return topicA.fields.order - topicB.fields.order;
          });
        });
    },
    backgroundStyle(page) {
      return `background-color:#${page.fields.color};`;
    },
    openWidget() {
      this.$zendesk.open();
    },
  },
};
</script>

<style lang="scss">
.content.help-center {
  min-height: auto;
}
</style>

<style lang="scss" scoped="">
.help-center-container {
  margin-bottom: 2rem;
}

.help-center {
  .title {
    margin-bottom: 1rem;
  }
}

.help-topics {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  padding: 1rem 0 2rem;

  .help-topic {
    height: 10rem;
    padding: 1rem 4rem 1rem 1rem;
    border-radius: 0.5rem;
    color: $plain-text;
    background-color: gray;
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .icon-container {
    @include flex-center;
    height: 2.25rem;
    width: 2.25rem;
    margin-bottom: 0.5rem;
    border-radius: 50%;
    background-color: $bg-color;
  }

  .icon {
    max-width: 100%;
    max-height: 100%;
  }

  @include bp-mediumsmall {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp-medium {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp-mediumlarge {
    padding-left: 2rem;
    padding-right: 2rem;

    .help-topic {
      padding: 1rem;
    }
  }

  @include bp-large {
    padding: 0;
    margin: 1rem 0 2rem;
  }
}

.contact {
  font-weight: 700;
  background: transparent;
}
</style>
